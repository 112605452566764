<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        作业管理
      </template>
      <template #input>
        <a-range-picker
          v-model="pickerData"
          valueFormat="YYYY-MM-DD"
          :placeholder="['发放开始日期','发放结束日期']"
        ></a-range-picker>
        <a-input placeholder="请输入作业名称" v-model="queryParams.homeworkTitle" />
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch">搜索</a-button>
        <a-button class="all_boder_btn" @click="addPage(1)" v-hasPermi="['exam:homework:config']">新建</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="" @change="callback">
        <a-tab-pane v-for="(item) in tabData" :key="item.courseId" :tab="item.courseName"></a-tab-pane>
      </a-tabs>
      <a-table
      class="table-template"
      :loading="loading"
      :rowKey="item=>item.id"
      :columns="columns"
      :scroll="{ x: 1700 }"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.pageNum,  
        defaultPageSize:queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>

        <template slot="homeworkValidDate" slot-scope="text">
          <span >{{text?text:'-'}}</span>
        </template>

        <template slot="scoreIssuedStatus" slot-scope="text">
          <span :class="text=='部分发布'?'state_color_red':text=='已发布'?'state_color_green':''">{{text?text:'-'}}</span>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <router-link to @click.native="addPage(2,item.id)">配置详情</router-link>
            <span v-show="item.isPublish">|</span>
            <router-link v-show="item.isPublish" to @click.native="studentPage(item.id)">学员配置</router-link>
            <span v-show="item.isPublish">|</span>
            <router-link v-show="item.isPublish" to @click.native="answerPage(item.id)">作答详情</router-link>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "作业名称",
    align:'center',
    dataIndex: "homeworkTitle"
  },
  {
    title: "作业有效期",
    align:'center',
    dataIndex: "homeworkValidDate",
    scopedSlots: { customRender: "homeworkValidDate" }
  },
  {
    title: "配置时间",
    align:'center',
    dataIndex: "createTime"
  },
  {
    title: "已发放人数",
    align:'center',
    dataIndex: "issuedCount"
  },
  {
    title: "作答人数",
    align:'center',
    dataIndex: "answeredCount"
  },
  {
    title: "已批阅人数",
    align:'center',
    dataIndex: "correctedCount"
  },
  {
    title: "成绩发布状态",
    align:'center',
    dataIndex: "scoreIssuedStatus",
    scopedSlots: { customRender: "scoreIssuedStatus" }
  },
  {
    title: "操作",
    fixed: "right",
    align:'center',
    width: "230px",
    scopedSlots: { customRender: "operation" }
  }
];
import HeaderBox from '@/components/HeaderBox.vue'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns, // 表头
      tableData:[], // 列表数据
      tabData:[], // tab列表
      tabName:'', // tab名称
      loading:false, // 列表加载
      total: 0, // 总数量
      pickerData:[],
      queryParams:{
        courseId: 0,  //tabID,
        pageNum: 1, // 页码
        pageSize: 10, // 页数
        homeworkTitle:'', // 作业名称
        configStartTime:'', // 发放开始日期
        configEndTime:'', // 发放结束日期
      },
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },

    // tab切换
    callback(e){
      this.tabData.forEach(element => {
        if(element.courseId == e){
          this.tabName = element.courseName
        }
      })

      this.queryParams.pageNum = 1
      this.queryParams.courseId = e
      this.getManageList()
    },
    
    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },

    // 作业新建/详情
    addPage(type,configId){
      this.$router.push({ path: "/admin/Subjective/workDetail", query: { state: type, configId: configId, courseId: this.queryParams.courseId,courseName:this.tabName } })
    },

    // 学员配置
    studentPage(id){
      this.$router.push({ path: "/admin/Subjective/studentManage", query: { configId: id,courseId: this.queryParams.courseId } })
    },

    // 学员作答详情
    answerPage(id){
      this.$router.push({ path: "/admin/Subjective/answerList", query: { configId: id,courseId: this.queryParams.courseId } })
    },
    
    // 查询tab列表
    getTabList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkConfig/getCourseByHomeSort",
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          if(res.data.length){
            this.tabData = res.data
            this.tabName = res.data[0].courseName
            this.queryParams.courseId = res.data[0].courseId
            this.getManageList()
          }
        } else {
          this.tabData = []
        }
      })
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.queryParams.configStartTime = (this.pickerData.length ? this.pickerData[0] : null)
      this.queryParams.configEndTime = (this.pickerData.length ? this.pickerData[1] : null)
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkConfig/getHomeWorkConfigList",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },
    
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getTabList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.all_content_box{
  .line-item{
    margin-bottom: 20px;
    display: flex;
    .all_left_name{
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .line-item:last-child{
    margin-bottom: 0;
  }
  .right{
    flex: 1;
    height: 40px;
    /deep/.ant-input{
      height: 40px;
    }
  }
  textarea.ant-input{
    flex: 1;
  }
}
.table-template{
  margin-top: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
</style>
